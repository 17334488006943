import { makeObservable, observable, reaction, runInAction, set } from 'mobx';
import { NotificationsStore } from './storage/notifications';
import { DeviceStore } from './storage/devices';
import { Website } from './settings';
import { DefaultFetcher } from './utils';

// Create a session store
export let SessionStore = observable({
    Auth: {
        isAuthenticated: false,
        isLoading: true,
    },
    Active: 'home',
    EventSourceStatus: "close",
    SyncEnabled: false,
});

// Check session on load
// XXX: Improve this
(async () => {
    if (/;?openpush=/.test(document.cookie)) {
        fetch(`${Website.api}/v1/session`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(async (resp) => {
            let data = await resp.json();
            runInAction(() => set(SessionStore, {
                Auth: {
                    isAuthenticated: data?.success,
                    isLoading: false,
                },
                SyncEnabled: data?.sync || false,
            }));
        }).catch(() => runInAction(() => set(SessionStore, {
            Auth: {
                isAuthenticated: false,
                isLoading: false,
            },
        })))
    } else {
        runInAction(() => set(SessionStore, {
            Auth: {
                isAuthenticated: false,
                isLoading: false,
            },
        }))
    }
})();

// Create a notifications store
export let Notifications = observable(new NotificationsStore());

// Create a device store
export let Devices = observable(new DeviceStore());

// Create a toast store (name, id)
export let Toasts = observable.map();

// TODO: Track `last` to pull the next set of data from the server
export async function FetchNotifications() {
    let resp = await DefaultFetcher(`${Website.api}/v1/notifications`, { method: 'GET' })
    if (resp.ok) {
        Notifications.set((await resp.json())?.notifications || [], true);
    }
}

export async function FetchDevices() {
    let resp = await DefaultFetcher(`${Website.api}/v1/devices`, { method: 'GET' })
    if (resp.ok) {
        Devices.set((await resp.json())?.devices || []);
    }
}

// Trigger initial data fetching
reaction(
    () => SessionStore.Auth.isAuthenticated,
    (authed) => {
        if (authed) {
            (async () => {
                await Promise.all([
                    FetchNotifications(),
                    FetchDevices(),
                ])
            })();
        }
    }
)

export default SessionStore;
window.SessionStore = SessionStore;
