import { toJS, makeObservable, observable, action, computed, set, has, remove, keys } from "mobx";

export class NotificationsStore extends Object {
    value = {};

    constructor(notifications = []) {
        super();
        makeObservable(this, {
            value: observable,
            add: action,
            set: action,
            remove: action,
            has: action,
            entries: computed,
            empty: computed,
        }).add(notifications);
    }

    // Add notification(s) to the store
    add(notifications) {
        set(this.value, (Array.isArray(notifications) ? notifications : [notifications]).reduce((acc, i) => {
            let item = i instanceof Notification ? i : new Notification(i);
            return {
                ...acc,
                [item.id]: item
            }
        }, {}));
    }

    set(notifications) {
        this.value = {};
        this.add(notifications);
    }

    // Remove notification(s) from the store
    remove(notifications) {
        let removals = (Array.isArray(notifications) ? notifications : [notifications])
            .map(i => i instanceof Notification ? i : new Notification(i));

        removals.forEach(i => {
            if (has(this.value, i.id)) {
                remove(this.value, i.id);
            }
        });

        return removals.some(i => !has(this.value, i.id))
    }

    has(id) {
        return has(this.value, id instanceof Notification ? id.id : id)
    }

    get entries() {
        return Object.entries(this.value).sort((a, b) => {
            return a[1].created_at < b[1].created_at ? 1 : (a[1].created_at > b[1].created_at ? -1 : 0)
        })
    }

    get empty() {
        return keys(this.value) <= 0
    }
}

export class Notification extends Object {
    value = {};

    constructor(content = {}) {
        super();
        makeObservable(this, {
            value: observable,
            id: computed,
            title: computed,
            level: computed,
            summary: computed,
            body: computed,
            isencrypted: computed,
            encrypted: computed,
            hasbody: computed,
            created_at: computed,
            date_string: computed,
            kv: computed,
            decrypt: action,
        })
        Object.assign(this.value, {
            ...(content || {}),
            date_string: dayjs(content.created_at).format('MM/DD/YYYY'),
        });
    }

    async decrypt(password) {
        try {
            const b64tobuf = (s) => Uint8Array.from(atob(s), (c) => c.charCodeAt(null));
            this.value.body = (new TextDecoder()).decode(await crypto.subtle.decrypt(
                {
                    name: 'AES-GCM',
                    iv: b64tobuf(this.value?.encrypted?.iv),
                },
                await crypto.subtle.deriveKey(
                    {
                        name: 'PBKDF2',
                        salt: b64tobuf(this.value?.encrypted?.salt),
                        iterations: this.value?.encrypted?.iterations || 100000,
                        hash: 'SHA-256',
                    },
                    await crypto.subtle.importKey('raw', (new TextEncoder()).encode(password), 'PBKDF2', false, ['deriveKey']),
                    {
                        name: 'AES-GCM',
                        length: 256
                    },
                    false,
                    ['decrypt'],
                ),
                b64tobuf(this.body),
            ));
            this.value.isencrypted = false;
            return Promise.resolve(true);
        } catch (e) {
            return Promise.reject(false);
        }
    }

    get id() {
        return this.value.id || 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/x|y/g, match => (Math.random() * 16 | (match === 'y' ? 8 : 0)).toString(16));
    }

    get title() {
        return this.value.title || this.summary;
    }

    get level() {
        return this.value.level || 0;
    }

    get summary() {
        return this.value.summary || '';
    }

    get isencrypted() {
        return this.value.isencrypted || false;
    }

    get encrypted() {
        return this.value.encrypted || null;
    }
    set encrypted(value) {
        this.value.encrypted = value;
    }

    get hasbody() {
        return this.value.hasbody || false;
    }

    get body() {
        return this.value.body || null; // TODO: Make this an API call?
    }
    set body(value) {
        this.value.body = value;
    }

    get created_at() {
        return this.value.created_at || (new Date()).toISOString();
    }

    get date_string() {
        return this.value.date_string;
    }

    get kv() {
        return [this.date_string, this.value];
    }
}