import { set, runInAction } from "mobx";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Website } from "@/lib/settings";
import { Toaster } from "@/components/ui/sonner";
import { toast } from "sonner";
import Loader from "@/components/ui/custom/loader";

export default function Component({ store }) {
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = (event) => {
        setIsLoading(true)
        event.preventDefault?.();
        try {
            fetch(`${Website.api}/v1/login`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Basic ${btoa(event.target.username.value + ":" + event.target.password.value)}`,
                },
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error("Invalid credentials");
                }
            }).then((data) => { // Login successful; do nothing with the result at this time
                runInAction(() => set(store, {
                    Auth: {
                        isAuthenticated: data?.success
                    },
                    SyncEnabled: data?.sync || false,
                }))
                setIsLoading(false)
            }).catch((error) => {
                toast.error("Login Error", {
                    description: error.message || error.error || "Unknown error; please report this to the OpenPush team!",
                })
                setIsLoading(false)
            });
        } catch (error) {
            toast.error("API Error", {
                description: error.message || error.error || "Unknown error; please report this to the OpenPush team!",
            })
            setIsLoading(false)
        }
        return false
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
            {
                store.Auth?.isLoading ?
                    <Loader />
                : (
                    <Card className="w-full max-w-md">
                        <form onSubmit={handleLogin}>
                            <CardHeader>
                                <CardTitle className="text-2xl font-bold text-center">OpenPush Lite</CardTitle>
                                <CardDescription className="text-center">
                                    The self-hosted WebPush API
                                </CardDescription>
                            </CardHeader>
                            <CardContent className="space-y-4">
                                <div className="space-y-2">
                                    <Label htmlFor="username">Username</Label>
                                    <Input
                                        id="username"
                                        type="username"
                                        placeholder="JohnDoe"
                                        required
                                    />
                                </div>
                                <div className="space-y-2">
                                    <Label htmlFor="password">Password</Label>
                                    <Input
                                        id="password"
                                        type="password"
                                        required
                                        placeholder="********"
                                    />
                                </div>
                            </CardContent>
                            <CardFooter>
                                <Button className="w-full" type="submit" disabled={isLoading}>
                                    {isLoading ? "Signing In..." : "Sign In"}
                                </Button>
                            </CardFooter>
                        </form>
                        <Separator />
                        <CardFooter>
                            <div className="mt-4 mb-4 md:mb-0">
                                <h2 className="text-md font-bold text-gray-800 dark:text-white mb-2">
                                    Made with indifference by the <a className=" text-gray-600 dark:text-gray-300 underline" rel="noreferrer" target="_blank" href="https://docs.openpu.sh/team">OpenPush team</a>
                                </h2>
                                <p className="text-sm text-gray-600 dark:text-gray-300">
                                    &copy; 2024 <a className=" text-gray-600 dark:text-gray-300 underline" rel="noreferrer" target="_blank" href="https://openpu.sh">OpenPush</a>. All rights reserved.
                                </p>
                            </div>
                        </CardFooter>
                    </Card>
                )
            }
            <Toaster richColors />
        </div>
    )
}