import { Button } from "@/components/ui/button";
import { Menu, X } from "lucide-react";
import { AbortedDeferredError, NavLink } from "react-router-dom";
import { runInAction, set } from "mobx";
import { observer } from "mobx-react-lite";
import { toast } from "sonner";
import { Website } from "@/lib/settings";

export const NavLinks = [
    { key: "notification", label: "Notifications", path: "/" },
    { key: "settings", label: "Settings", path: "/settings" },
    { key: "docs", label: "Docs", path: "https://docs.openpu.sh/", target: "_blank", rel: "noopener noreferrer" },
];

export function getNavLink(key) {
    return NavLinks.find((link) => link.key === key);
}

const Nav = observer(({ options, store, toggles }) => {
    function logout() {
        fetch(`${Website.api}/v1/logout`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(async () => {
            runInAction(() => set(SessionStore, {
                Auth: {
                    isAuthenticated: false,
                },
            }));
        }).catch((error) => {
            toast.error("Failed to logout.", {
                description: error.message || error.error || "Unknown error; please report this to the OpenPush team!",
            })
        })
    }

    return (
        <div className="flex items-center space-x-4">
            <nav className="hidden md:block">
                <ul className="flex space-x-4">
                    {
                        NavLinks.map((link) => {
                            return ((!("enabled" in link) || link.enabled({ store })) && (
                                <li key={link.key}>
                                    <NavLink
                                        className={
                                            ({ isActive }) => {
                                                return `hover:underline text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white ${isActive ? "underline font-bold text-primary dark:text-primary" : ""}`
                                            }
                                        }
                                        to={link.path}
                                        onClick={() => {
                                            link.onClick?.(store)
                                        }}
                                        state={{ active: link.key }}
                                        target={link.target || "_self"}
                                        rel={link.rel || ""}
                                    >
                                        <span>
                                            {link.label}
                                        </span>
                                    </NavLink>
                                </li>
                            ) || null)
                        })
                    }
                    <li>
                        <a className="hover:underline text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white cursor-pointer"
                            onClick={
                                () => logout()
                            }
                        >
                            Sign Out
                        </a>
                    </li>
                </ul>
            </nav>
            <Button
                variant="ghost"
                size="icon"
                className="md:hidden text-gray-600 dark:text-gray-300"
                onClick={toggles.mobileMenu}
                aria-label="Toggle mobile menu"
            >
                {
                    options.mobileMenuOpen ?
                        <X className="h-5 w-5" />
                        :
                        <Menu className="h-5 w-5" />
                }
            </Button>
        </div >
    );
});

const MobileNav = observer(({ options, store, toggles }) => {
    return options.mobileMenuOpen && (
        <nav className="z-20 md:hidden bg-white dark:bg-gray-800 shadow-lg">
            <ul className="py-2" onClick={() => toggles.mobileMenu()}>
                {
                    NavLinks.map((link) => (!("enabled" in link) || link.enabled({ store })) && (
                        <li key={link.key}>
                            <NavLink
                                className={
                                    ({ isActive }) => {
                                        return `block px-2 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 ${isActive ? "underline font-bold text-primary dark:text-primary bg-gray-100 dark:bg-gray-700" : ""}`
                                    }
                                }
                                to={link.path}
                                onClick={() => {
                                    runInAction(() => {
                                        store.Active = link.key
                                    })
                                    link.onClick?.(store)
                                }}
                                target={link.target || "_self"}
                                rel={link.rel || ""}
                            >
                                {link.label}
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
        </nav>
    )
});

export {
    Nav,
    MobileNav
}