import { createBrowserRouter, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { Component as Error404 } from "./components/pages/codes/404";
import { SessionStore } from "@/lib/stores";
import { useEffect, useState } from "react";
import Login from "./components/pages/Login";
import { observer } from "mobx-react-lite";
import ErrorBoundary from "@/components/ui/custom/errorboundary";

const RequireAuth = observer(({ store, children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!store.Auth?.isAuthenticated) {
            navigate("/");
        }
    }, [store.Auth?.isAuthenticated]);

    return store.Auth?.isAuthenticated ? children : <Login store={store} />;
})

export default createBrowserRouter([
    {
        path: "/",
        element: (
            <ErrorBoundary>
                <RequireAuth store={SessionStore}>
                    <Layout store={SessionStore} />
                </RequireAuth>
            </ErrorBoundary>
        ),
        id: "root",
        children: [
            {
                index: true,
                async lazy() {
                    let Notifications = (await import("./components/pages/Notifications")).Component
                    return { Component: () => <Notifications store={SessionStore} /> };
                },
                id: "notifications",
            },
            {
                path: "settings",
                async lazy() {
                    let Settings = (await import("./components/pages/Settings")).Component;
                    return { Component: () => <Settings store={SessionStore} /> };
                },
                id: "settings",
            },
            {
                path: "*",
                element: <Error404 />,
                id: "404",
            },
        ],
    },
], {
    future: {
        v7_normalizeFormMethod: true,
    }
});